import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import SearchResults from "./SearchResults";
import Home from "./Home";
import CarDetails from "./CarDetails";
import Title from './Title';
import ManagePage from "./ManagePage";
import Footer from "./Footer";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import MyProfile from "./MyProfile";
import {AuthProvider} from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import {useState} from "react";
import CookieBanner from "./CookieBanner";
import Modal from "./Modal";
import StorageLayout from "./managementPortal/layout";
import ImportPage from "./managementPortal/ImportPage";

function App() {
    const privacyPolicy = "Privacy Notice for Better Us Auto\n" +
        "Last Updated: 11/26/2023\n" +
        "\n" +
        "1. Introduction\n" +
        "Welcome to Better Us Auto. We respect your privacy and are committed to protecting your personal data. This privacy notice will inform you about how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.\n" +
        "\n" +
        "2. Data Collection\n" +
        "a. Personal Data\n" +
        "We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:\n" +
        "\n" +
        "Identity Data: Includes first name, last name, username or similar identifier.\n" +
        "Contact Data: Includes billing address, delivery address, email address and telephone numbers.\n" +
        "Technical Data: Includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.\n" +
        "Usage Data: Includes information about how you use our website, products, and services, specifically your searches regarding used cars.\n" +
        "b. How is Your Personal Data Collected?\n" +
        "We use different methods to collect data from and about you including through direct interactions (filling in forms or corresponding with us) and automated technologies or interactions (as you interact with our website, we may automatically collect Technical Data and Usage Data).\n" +
        "\n" +
        "3. Use of Data\n" +
        "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:\n" +
        "\n" +
        "To provide you with information, products, or services that you request from us.\n" +
        "To manage our relationship with you.\n" +
        "To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data).\n" +
        "4. Data Security\n" +
        "We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.\n" +
        "\n" +
        "5. Your Legal Rights\n" +
        "Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, restriction, transfer, to object to processing, to portability of data and (where the lawful ground of processing is consent) to withdraw consent.\n" +
        "\n" +
        "6. Contact Us\n" +
        "If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact us using the details set out below.\n" +
        "\n" +
        "Contact Details:\n" +
        "\n" +
        "Email address: xrbehappy@gmail.com"

    const cookiePolicy = "Last Updated: 11/26/2023\n" +
        "\n" +
        "1. Introduction\n" +
        "By using the Better Us Auto website, you agree to the use of cookies as described in this Cookie Use Agreement.\n" +
        "\n" +
        "2. What are Cookies?\n" +
        "Cookies are small text files that are placed on your machine to help the site provide a better user experience. In general, cookies are used to retain user preferences.\n" +
        "\n" +
        "3. How We Use Cookies\n" +
        "We use cookies to:\n" +
        "\n" +
        "Understand and save user's preferences for future visits.\n" +
        "Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future.\n" +
        "Record your IP address and what cars you search during your visit for personalized recommendations and advertising.\n" +
        "4. Managing Cookies\n" +
        "You may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the Help section of your browser.\n" +
        "\n" +
        "5. Consent\n" +
        "By using our website, you hereby consent to our Cookie Use Agreement and agree to its terms.\n" +
        "\n" +
        "6. Changes to this Agreement\n" +
        "We may update our Cookie Use Agreement from time to time. We will notify you of any changes by posting the new Cookie Use Agreement on this page.\n" +
        "\n" +
        "7. Contact Us\n" +
        "If you have any questions about our Cookie Use Agreement, please contact us.\n" +
        "\n" +
        "Contact Details:\n" +
        "\n" +
        "Email address: xrbehappy@gmail.com"

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const openModalWithContent = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    return (
        <AuthProvider>
            <Router>
                <Title/>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/searchResults" element={<SearchResults/>}/>
                    <Route exact path="/carDetails" element={<CarDetails/>}/>
                    <Route exact path="/guanlizhan" element={<ManagePage/>}/>
                    <Route path="/storageguanli" element={<StorageLayout>
                        <ImportPage />
                    </StorageLayout>}/>
                    <Route exact path="/login" element={<LoginPage/>}/>
                    <Route exact path="/register" element={<RegisterPage/>}/>
                    <Route path="/myProfile" element={<ProtectedRoute />}>
                        <Route index element={<MyProfile />} />
                    </Route>
                </Routes>
                <Footer/>
                <CookieBanner
                    onPrivacyClick={() => openModalWithContent(privacyPolicy)}
                    onCookieClick={() => openModalWithContent(cookiePolicy)}
                />
                <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    content={modalContent}
                />
            </Router>
        </AuthProvider>
    );
}

export default App;