// ProtectedRoute.js
import React, {useState, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = () => {
    const [isVerified, setIsVerified] = useState(null);  // New state to hold verification status
    const navigate = useNavigate();

    useEffect(() => {
        // Replace '/verify-auth' with your server's verification endpoint
        axios.get('/user/verifyAuth', {
            params: {
                _: new Date().getTime(),  // cache-busting query parameter
            },
        })
            .then(response => {
                if (response.status === 200) {
                    setIsVerified(true);
                }
            })
            .catch(error => {
                console.error('Authentication verification failed:', error);
                setIsVerified(false);
            });
    }, [navigate]);  // Dependency array updated

    useEffect(() => {
        if (isVerified === false) {
            navigate('/login');
        }
    }, [isVerified, navigate]);

    return isVerified ? <Outlet/> : null;  // Render Outlet only if isVerified is true
};

export default ProtectedRoute;
