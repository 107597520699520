import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { Helmet } from 'react-helmet';
import {Button} from "react-bootstrap";
import "./ManagePage.css"

const ManagePage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [visitTimes, setVisitTimes] = useState(0);
    const [lastScannedTime, setLastScannedTime] = useState('');
    const [totalNumOfCars, setTotalNumOfCars] = useState(0);
    const [totalNumOfDetailedCars, setTotalNumOfDetailedCars] = useState(0);
    const [searchInfo, setSearchInfo] = useState([]);
    const [userRequest, setUserRequest] = useState([]);
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [visitInfo, setVisitInfo] = useState([]);

    useMemo(() => {
        axios
            .get('/api/getTotalVisitTimes')
            .then(response => {
                setVisitTimes(response.data)
            })
            .catch(error => {
            });

        axios
            .get('/api/getVisitInfoOrderByVisitTimes')
            .then(response => {
                setVisitInfo(response.data)
            })
            .catch(error => {
            });

        // axios
        //     .get('/api/getLastScannedTime')
        //     .then(response => {
        //         setLastScannedTime(response.data)
        //     })
        //     .catch(error => {
        //     });
        //
        // axios
        //     .get('/api/getTotalCarsInDB')
        //     .then(response => {
        //         setTotalNumOfCars(response.data)
        //     })
        //     .catch(error => {
        //     });

        axios
            .get('/api/getSearchInfo')
            .then(response => {
                setSearchInfo(response.data)
            })
            .catch(error => {
            });

        axios
            .get('/api/getUserInterest')
            .then(response => {
                setUserRequest(response.data)
            })
            .catch(error => {
            });

        // axios
        //     .get('/api/getTotalNumberOfDetailedCars')
        //     .then(response => {
        //         setTotalNumOfDetailedCars(response.data)
        //     })
        //     .catch(error => {
        //     });

        // getPassword()
    }, [])

    function triggerListOfCarsScanning() {
        setIsLoading(true);
        let url = '/api/scanPopularCars'
        axios
            .put(url)
            .then(response =>
                turnOnTriggeredText()
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
                turnOffTriggeredText()
            });
    }

    function triggerCarDetailsScanning() {
        setIsLoading(true);
        let url = '/api/scanDetailedCars'
        axios
            .put(url)
            .then(response =>
                turnOnTriggeredText()
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
                turnOffTriggeredText()
            });
    }

    function triggerCarDetailsScanningWithAPI() {
        setIsLoading(true);
        let url = '/api/getCarDetailsUsingAPI?token=' + token
        axios
            .put(url)
            .then(response =>
                turnOnTriggeredText()
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
                turnOffTriggeredText()
            });
    }

    function getPassword() {
        setIsLoading(true);
        let url = '/api/getPassword'
        axios
            .get(url)
            .then(response =>
                setPassword(response.data)
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
            });
    }

    function refreshPassword() {
        setIsLoading(true);
        let url = '/api/getSeriesId'
        axios
            .put(url)
            .then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
            });
    }

    function deleteUserRequest(id) {
        setIsLoading(true);
        let url = '/api/deleteUserRequest?id=' + id
        axios
            .put(url)
            .then(response =>
                window.location.reload()
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
            });
    }

    const turnOffTriggeredText = () => {
        document.getElementById("triggeredText").style.display = "none";
    }

    const turnOnTriggeredText = () => {
        document.getElementById("triggeredText").style.display = "block";
    }

    const handleTokenChange = event => {
        setToken(event.target.value);
    };

    return (
        <>
            <body>
            {isLoading ? <LoadingSpinner/> : ""}
            <Helmet>
                <title>Better Us Auto</title>
            </Helmet>
            <div className={"dataDiv"}>
                <Button disabled={true} onClick={triggerListOfCarsScanning}>Trigger List Of Cars Scanning</Button>
                <Button disabled={true} onClick={triggerCarDetailsScanning}>Trigger Car Details Scanning</Button>
                <br/>
                <input className={"tokenInput"} type="text" id="tokenInput" name="token" value={token}
                       onChange={handleTokenChange}></input>
                <Button disabled={true} onClick={triggerCarDetailsScanningWithAPI}>Trigger Car Details Scanning with API</Button>
                {/*<p id={"triggeredText"}>Scanning triggered</p>*/}
                {/*<h2> Last scanned time: {lastScannedTime}</h2>*/}
                {/*<h2>Number of Stored cars: {totalNumOfCars}</h2>*/}
                {/*<h2>Number of Stored Detailed cars: {totalNumOfDetailedCars}</h2>*/}
                {/*<h2>Current password: {password}</h2>*/}
                <Button onClick={refreshPassword}>Caution: Refresh SeriesID!!!</Button>
                <h2>Number of visits: {visitTimes}</h2>
                <div className={"visitStatDiv"}>
                    <h2>Visit Info: </h2>
                    <table className={"visitStatTable"} border="1">
                        <thead>
                        <tr>
                            <th>IP Address</th>
                            <th>Last Visit Time</th>
                            <th>Location</th>
                            <th>Searched Cars</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            visitInfo.map((info) => {
                                return (
                                    <tr key={info.ipAddress}>
                                        <td>{info.ipAddress}</td>
                                        <td>{info.visitTime}</td>
                                        <td>{info.location}</td>
                                        <td>{info.searched}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <h2>Top search: </h2>
                {
                    searchInfo.map((info) => (
                        <div>
                            <p>{info.split(",")[0]} | {info.split(",")[1]} | {info.split(",")[2]}</p>
                        </div>
                    ))
                }
                <div className={"userRequestDiv"}>
                    <h2>User requests: </h2>
                    <table className={"userRequestTable"} border="1">
                        <thead>
                        <tr>
                            <th>No.</th>
                            <th>VIN</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Comment</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            userRequest.map((info) => {
                                const data = info.split(",");
                                return (
                                    <tr key={data[0]}>
                                        <td>{data[0]}</td>
                                        <td>{data[1]}</td>
                                        <td>{data[2]}</td>
                                        <td>{data[3]}</td>
                                        <td>{data[4]}</td>
                                        <td>{data[5]}</td>
                                        <td>{data[6]}</td>
                                        <td>
                                            <button onClick={() => deleteUserRequest(data[0])}>Delete</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                {/*<h3>No. | VIN | First Name | Last Name | Phone | Email | Comment</h3>*/}
                {/*{*/}
                {/*    userRequest.map((info) => (*/}
                {/*        <div>*/}
                {/*            <p>{info.split(",")[0]} | {info.split(",")[1]} | {info.split(",")[2]} | {info.split(",")[3]} | {info.split(",")[4]} | {info.split(",")[5]} | {info.split(",")[6]}</p>*/}
                {/*        </div>*/}
                {/*    ))*/}
                {/*}*/}
            </div>
            </body>
        </>
    )
};

export default ManagePage;