import React, { useState, useEffect } from 'react';
import "./CookieBanner.css";
import Modal from "./Modal";
const CookieBanner = ({ onPrivacyClick, onCookieClick }) => {
    const [showBanner, setShowBanner] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (cookieConsent !== 'y') {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'y');
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div className={"bannerDiv"}>
            <p>We use cookies to improve your experience. By continuing to use our site, you accept our
                <button className={"cookieButton"} onClick={onPrivacyClick}> Privacy Policy </button> and
                <button className={"cookieButton"} onClick={onCookieClick}> Cookie Policy </button></p>
            <button className={"cookieButton"} onClick={handleAccept}>Accept</button>
        </div>
    );
};

export default CookieBanner;
