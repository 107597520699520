import React from 'react';
import "./Modal.css"

const Modal = ({ open, onClose, content }) => {
    if (!open) return null;
    return (
        <div onClick={onClose} className='overlay'>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className='modalContainer'
            >
                <div className='modalRight'>
                    <div className='content'>
                        <p>{content}</p>
                    </div>
                    <div className='btnContainer'>
                        <button className='btnPrimary' onClick={onClose}>
                            <span className='bold'>OK</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;