import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Select from 'react-select';

export default function ImportPage() {
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userStores, setUserStores] = useState([]);
  const [parsedData, setParsedData] = useState(null);

  // Fetch users when component mounts
  useEffect(() => {
    fetchUsers();
  }, []);

  // Filter users when search term changes
  useEffect(() => {
    if (!searchTerm) {
      setFilteredUsers(users);
      return;
    }

    const filtered = users.filter(user => 
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone.includes(searchTerm)
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  const fetchUsers = async () => {
    try {
      const response = await fetch('/StorageManagement-1.0/user/all');
      if (!response.ok) throw new Error('Failed to fetch users');
      const data = await response.json();
      setUsers(data);
      setFilteredUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setErrors(prev => [...prev, 'Failed to load users']);
    }
  };

  // Format user data for react-select
  const userOptions = filteredUsers.map(user => ({
    value: user.id,
    label: `${user.email} (${user.phone}) - ID: ${user.id}`,
    user: user
  }));

  const validateExcelData = (data) => {
    const errors = [];
    const availableStoreIds = new Set(userStores.map(store => store.id.toString()));

    if (!selectedUser) {
      errors.push('Please select a user before importing data');
      return errors;
    }

    data.forEach((row, index) => {
      if (!row['Merchandise Name (Required)']) {
        errors.push(`Row ${index + 1}: Missing merchandise name`);
      }
      if (!row['Creator ID (Required)']) {
        errors.push(`Row ${index + 1}: Missing creator ID`);
      }
      if (!row['Tag Name (Required)']) {
        errors.push(`Row ${index + 1}: Missing tag name`);
      }
      if (!row['Quantity (Required, Number)'] || isNaN(row['Quantity (Required, Number)'])) {
        errors.push(`Row ${index + 1}: Invalid quantity`);
      }
      if (!row['Stock Price (Required, Number)'] || isNaN(row['Stock Price (Required, Number)'])) {
        errors.push(`Row ${index + 1}: Invalid stock price`);
      }
      
      // Validate Store ID
      const storeId = row['Store ID (Required)']?.toString();
      if (!storeId) {
        errors.push(`Row ${index + 1}: Missing store ID`);
      } else if (!availableStoreIds.has(storeId)) {
        errors.push(`Row ${index + 1}: Invalid store ID. Must be one of: ${Array.from(availableStoreIds).join(', ')}`);
      }
    });
    return errors;
  };

  const handleImport = async (e) => {
    e.preventDefault();
    setUploading(true);
    setErrors([]);

    try {
      const file = e.target.excel.files[0];
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setParsedData(jsonData);
        console.log('Parsed Excel data:', jsonData);

        const validationErrors = validateExcelData(jsonData);
        if (validationErrors.length > 0) {
          setErrors(validationErrors);
          setUploading(false);
          return;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(jsonData));
        
        const imageFiles = e.target.images?.files;
        if (imageFiles) {
          console.log('Uploading images:', Array.from(imageFiles).map(f => f.name));
          Array.from(imageFiles).forEach(file => {
            formData.append('images', file);
          });
        }

        console.log('Sending request to:', '/StorageManagement-1.0/merchandise/import');
        const response = await fetch('/StorageManagement-1.0/merchandise/import', {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json',
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Import failed:', errorData);
          throw new Error(errorData.message || 'Import failed');
        }

        const result = await response.json();
        console.log('Import successful:', result);
        alert('Import successful!');
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      setErrors([error.message]);
    } finally {
      setUploading(false);
    }
  };

  // Custom styles for react-select
  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: state.isSelected ? '#e5e7eb' : 'white',
      '&:hover': {
        backgroundColor: '#f3f4f6'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    })
  };

  // Function to fetch user's stores
  const fetchUserStores = async (userId) => {
    try {
      const response = await fetch(`/StorageManagement-1.0/user/${userId}/stores`);
      if (!response.ok) throw new Error('Failed to fetch user stores');
      const data = await response.json();
      setUserStores(data);
    } catch (error) {
      console.error('Error fetching user stores:', error);
      setErrors(prev => [...prev, 'Failed to load user stores']);
    }
  };

  // Update the onChange handler for user selection
  const handleUserSelect = (selectedOption) => {
    setSelectedUser(selectedOption);
    if (selectedOption) {
      fetchUserStores(selectedOption.value);
    } else {
      setUserStores([]); // Clear stores when user is deselected
    }
  };

  const handleTemplateDownload = async () => {
    try {
      const response = await fetch('/StorageManagement-1.0/api/resources/templates/import');
      if (!response.ok) {
        throw new Error('Failed to download template');
      }
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'import_template.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading template:', error);
      setErrors(prev => [...prev, 'Failed to download template']);
    }
  };

  return (
    <div className="space-y-12">
      {/* User Search Section */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-6">Find User</h2>
        
        {/* Search and Dropdown Container */}
        <div className="flex items-start space-x-8">
          {/* Left Side - Search */}
          <div className="flex-1">
            <label htmlFor="userSearch" className="block text-sm font-medium text-gray-700 mb-2">
              Search by Email or Phone
            </label>
            <div className="relative">
              <input
                type="text"
                id="userSearch"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                placeholder="Enter email or phone number"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {/* Search Results Dropdown */}
              {searchTerm && filteredUsers.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md border border-gray-200">
                  {filteredUsers.map(user => (
                    <div
                      key={user.id}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black"
                      onClick={() => {
                        setSelectedUser({
                          value: user.id,
                          label: `${user.email} (${user.phone}) - ID: ${user.id}`,
                          user: user
                        });
                        setSearchTerm('');
                      }}
                    >
                      {user.email} ({user.phone})
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Vertical Separator */}
          <div className="h-20 w-px bg-gray-200 my-2"></div>

          {/* Right Side - Dropdown */}
          <div className="flex-1">
            <label htmlFor="userSelect" className="block text-sm font-medium text-gray-700 mb-2">
              Select User from List
            </label>
            <Select
              id="userSelect"
              options={userOptions}
              value={selectedUser}
              onChange={handleUserSelect}
              styles={selectStyles}
              placeholder="Select a user..."
              isClearable
              isSearchable
            />
          </div>
        </div>

        {/* Selected User Details */}
        {selectedUser && (
          <div className="mt-8 p-4 bg-gray-50 rounded-md">
            <h3 className="text-lg font-medium mb-3">Selected User Details</h3>
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-4">
                <div>
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">{selectedUser.user.email}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900">{selectedUser.user.phone}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">User ID</dt>
                  <dd className="mt-1 text-sm text-gray-900">{selectedUser.value}</dd>
                </div>
              </div>
              
              <div>
                <dt className="text-sm font-medium text-gray-500 mb-2">Accessible Stores</dt>
                {userStores.length > 0 ? (
                  <dd className="mt-1">
                    <ul className="space-y-2">
                      {userStores.map((store) => (
                        <li 
                          key={store.id} 
                          className="text-sm bg-white px-3 py-2 rounded-md border border-gray-200"
                        >
                          <span className="font-medium text-gray-700">ID: {store.id}</span>
                          <span className="mx-2 text-gray-300"> | </span>
                          <span className="text-gray-900">Name: {store.name}</span>
                          {store.address && (
                            <>
                              <span className="mx-2 text-gray-300"> | </span>
                              <span className="text-gray-600">Address: {store.address}</span>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </dd>
                ) : (
                  <dd className="text-sm text-gray-500 italic">No stores assigned</dd>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Excel Import Section */}
      <div className="bg-white rounded-lg shadow p-6 mt-8">
        <h2 className="text-xl font-semibold mb-6">Import Merchandise Data</h2>
        
        <div className="mb-6">
          <h3 className="text-lg font-medium mb-2">Excel Template</h3>
          <p className="text-sm text-gray-600 mb-2">
            Please download and use our template for importing data:
          </p>
          <button
            onClick={handleTemplateDownload}
            className="text-primary hover:text-primary-hover"
          >
            Download Template
          </button>
        </div>

        <form onSubmit={handleImport} className="space-y-4">
          <div>
            <label htmlFor="excel">Excel File</label>
            <input
              id="excel"
              name="excel"
              type="file"
              accept=".xlsx,.xls"
              required
            />
            <p className="text-sm text-gray-500">
              Required columns: Merchandise Name, Creator ID, Tag Name, Property Combo, 
              Quantity, Stock Price, Store ID, Image Reference (optional)
            </p>
          </div>

          <div>
            <label htmlFor="images">Product Images (Optional)</label>
            <input
              id="images"
              name="images"
              type="file"
              multiple
              accept="image/*"
            />
            <p className="text-sm text-gray-500">
              Image filenames should match the Image Reference column in your Excel file
            </p>
          </div>

          {errors.length > 0 && (
            <div className="bg-red-50 border border-red-200 rounded p-4">
              <h4 className="text-red-800 font-medium mb-2">Import Errors:</h4>
              <ul className="list-disc pl-5">
                {errors.map((error, index) => (
                  <li key={index} className="text-red-600 text-sm">{error}</li>
                ))}
              </ul>
            </div>
          )}

          <button
            type="submit"
            disabled={uploading}
            className="bg-primary text-white hover:bg-primary-hover px-4 py-2 rounded-md"
          >
            {uploading ? 'Importing...' : 'Import Data'}
          </button>
        </form>
      </div>

      {/* Available Store IDs Section */}
      {selectedUser && userStores.length > 0 && (
        <div className="mt-4 p-4 bg-blue-50 rounded-md">
          <h4 className="text-sm font-medium text-blue-800 mb-2">Available Store IDs for Import:</h4>
          <ul className="list-disc pl-5">
            {userStores.map((store) => (
              <li key={store.id} className="text-sm text-blue-600">
                Store ID: {store.id} - {store.name}
                {store.address && ` (${store.address})`}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Data Preview Section */}
      {parsedData && (
        <div className="mt-8 bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4">Data Preview</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {Object.keys(parsedData[0] || {}).map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {parsedData.slice(0, 5).map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, i) => (
                      <td key={i} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {parsedData.length > 5 && (
              <p className="mt-2 text-sm text-gray-500">
                Showing first 5 rows of {parsedData.length} total rows
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
