import "./LoginPage.css"
import {GoogleLogin} from "@react-oauth/google";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import React, {useContext, useState} from "react";
import {AuthContext} from "./AuthContext";
import LoadingSpinner from "./LoadingSpinner";
import {useTranslation} from "react-i18next";

const LoginPage = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const {login} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true)
            const response = await axios.post('/user/authenticate', {
                "email": email,
                "password": password
            });
            if (response.status === 200) {
                login();
                navigate("/myProfile", response.data);
            } else {
                setError(t('home.loginFailed'));
                setIsLoading(false)
            }
        } catch (error) {
            setError(t('home.loginFailed'));
            console.error('Login failed:', error.response ? error.response.data : error.message);
            setIsLoading(false)
        }
    };

    return (
        <div className="login-page">
            {isLoading ? <LoadingSpinner/> : ""}
            <div className="login-outer-form">
                <p className={"titleP"}>Login to see&save your favorite cars, contact us about your interests and
                    more!</p>
                <form className="login-form" onSubmit={handleSubmit} autoComplete="on">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button>Sign In</button>
                    {error && <div className="loginErrorMessage">{error}</div>}
                    <div className={"googleLogin"}>
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                console.log(credentialResponse);
                                axios
                                    .post('/user/postGoogleSignIn', {
                                        credential: credentialResponse.credential
                                    },{
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    })
                                    .then(response =>
                                        navigate("/myProfile", {state: response.data})
                                    ).then(response => {

                                })
                                    .catch(error => {
                                        setError(t('home.loginFailed'))
                                    });
                            }}

                            onError={() => {
                                console.log('Login Failed');
                            }}

                        />
                    </div>
                    <p className="message">Don't have account? <a href="/register">Register one!</a></p>
                </form>
            </div>
        </div>

    )
};

export default LoginPage;